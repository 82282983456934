import { string } from 'yup';

export function formatMoney(amount) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(amount);
}

export function transformDate(str) {
  const date = new Date(+str);
  const [month, day, year] = date.toLocaleDateString().split('/');

  return [
    year,
    month < 10 ? '0' + month : month,
    day < 10 ? '0' + day : day
  ].join('-');
}

export function mergeClasses(...classes) {
  return classes
    .filter(Boolean)
    .join(' ');
}

export function getConditionalDateType(message) {
  return string().test({
    message,
    /* eslint ignore */
    test: val => val === '' || new Date(val) != 'Invalid Date'
  });
}