import { createStore, combineReducers } from 'redux';

import user from './reducers/user';
import loaded from './reducers/loaded';
import snackbar from './reducers/snackbar';

const reducer = combineReducers({
  loaded,
  snackbar,
  user,
});

export default createStore(reducer);