// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-features-js": () => import("./../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-grads-js": () => import("./../src/pages/grads.js" /* webpackChunkName: "component---src-pages-grads-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-refund-policy-js": () => import("./../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-signup-js": () => import("./../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

