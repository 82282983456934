const baseColors = {
  blue: "#0072ff",
  dark: "#2c2f3b",
  grayDark: "#4c5267",
  gray: "#7b839e",
  grayLight: "#dedfe3",
  whiteDark: "#f7f9fb",
}

const baseButton = {
  borderRadius: "3px",
  display: "inline-block",
  fontSize: 2,
  lineHeight: 1.5,
  transition: "all .2s ease-in-out",
  fontFamily: "body",
  cursor: "pointer",
}

const baseInput = {
  bg: "white",
  border: "1px solid",
  borderColor: "grayLight",
  borderRadius: "3px",
  boxShadow: "none",
  color: "grayDark",
  display: "inline-block",
  fontSize: 2,
  fontWeight: "normal",
  lineHeight: 1.5,
  width: "100%",
  maxWidth: "100%",
  p: ["8px 15px"],
  height: "48px",
  verticalAlign: "baseline",
  fontFamily: "body",
  WebkitAppearance: "none",
}

export default {
  colors: {
    ...baseColors,
    text: baseColors.grayDark,
    background: baseColors.whiteDark,
    heading: baseColors.dark,
    primary: baseColors.blue,
  },
  breakpoints: ["600px", "800px", "1140px"],
  space: [0, 4, 8, 16, 24, 32, 64, 128, 256, 512],
  fonts: {
    body: "'Nunito Sans', sans-serif",
    heading: "inherit",
  },
  fontSizes: [12, 14, 16, 18, 24, 32, 48, 64, 72],
  fontWeights: {
    body: 400,
    heading: 900,
    bold: 700,
  },
  lineHeights: {
    body: 1.66667,
    heading: 1.2,
  },
  text: {
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      color: "heading",
    },
  },
  sizes: {
    container: 1172,
  },
  buttons: {
    primary: {
      ...baseButton,
      color: "white",
      bg: baseColors.blue,
      p: ["4px 15px"],
      border: "2px solid",
      borderColor: baseColors.blue,
      "&:hover": {
        opacity: 0.75,
      },
    },
    secondary: {
      ...baseButton,
      color: baseColors.blue,
      bg: "transparent",
      p: ["8px 30px"],
      border: "2px solid",
      borderColor: baseColors.blue,
      "&:hover": {
        color: "white",
        bg: baseColors.blue,
      },
    },
    warning: {
      ...baseButton,
      bg: '#a71212',
      '&:hover': {
        bg: '#cd5c5c'
      }
    },
    flat: {
      ...baseButton,
      color: baseColors.grayDark,
      bg: "white",
      p: ["12px 40px"],
      boxShadow: "0 20px 30px 0 rgba(76, 82, 103, 0.2)",
      "&:hover": {
        boxShadow: "none",
      },
    },
  },
  forms: {
    checkboxWrapper: {
      margin: '10px 0'
    },
    checkboxLabel: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer'
    },
    checkbox: {
      marginRight: '10px'
    },
    label: {},
    input: {
      ...baseInput,
    },
    select: {
      ...baseInput,
    },
    textarea: {
      ...baseInput,
      display: "block",
      resize: "none",
      height: "auto",
      maxHeight: "300px",
    },
    inputGroup: {
      marginBottom: '20px'
    },
    error: {
      margin: '5px 0',
      color: 'red',
      textAlign: 'left'
    }
  },
  centeredInner: {
    mx: "auto",
    maxWidth: ["100%", "500px", "590px"],
    textAlign: "center",
  },
  layout: {
    container: {
      px: [3, 4],
    },
  },
  tables: {
    striped: {
      border: "1px solid",
      borderColor: "gray",
      width: "100%",
      maxWidth: "100%",
      backgroundColor: "white",
      borderCollapse: "collapse",
      textAlign: "left",
      color: "dark",
      "tbody tr:nth-of-type(odd)": {
        backgroundColor: "grayLight",
      },
      "td, th": {
        border: "1px solid",
        borderColor: "gray",
        p: 2,
      },
      th: { color: "black" },
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
    },
    p: {
      fontSize: [2, 3],
    },
    h1: {
      variant: "text.heading",
      fontSize: [5, 6, 7],
    },
    h2: {
      variant: "text.heading",
      fontSize: [4, 5],
    },
  },
}