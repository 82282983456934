export default (state = { content: null, isOpen: false }, action) => {
  switch(action.type) {
    case 'OPEN_SNACKBAR':
      return {
        content: action.content,
        isOpen: true
      };
    
    case 'CLOSE_SNACKBAR':
      return {
        ...state,
        isOpen: false
      };
    
    default:
      return state;
  }
}