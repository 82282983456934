import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Close, Message } from 'theme-ui';

import styles from './Snackbar.module.css';
import { mergeClasses } from '../lib/utilities';

export default function Snackbar() {
  const { content, isOpen } = useSelector(state => state.snackbar);
  const dispatch = useDispatch();

  return (
    <Message className={mergeClasses(styles.snackbar, isOpen && styles.active)}>
      <div>
        {content}
      </div>
      <Close
        onClick={() => dispatch({ type: 'CLOSE_SNACKBAR' })}
        className={styles.close}
      />
    </Message>
  )
}