/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import { ClientContext, GraphQLClient } from 'graphql-hooks';
import { Provider } from 'react-redux';
import { navigate } from '@reach/router';
import { view, lensPath } from 'rambda';

import Snackbar from './src/components/Snackbar';
import store from './src/redux/store';

const client = new GraphQLClient({
  url: '/api/gql',
  onError({ result }) {
    const errors = view(lensPath(['error', 'graphQLErrors']), result);

    console.log(errors);
    if(errors.filter(err => err.message === 'Unauthorized').length) {
      localStorage.setItem('redirectUrl', window.location.pathname);

      navigate('/app/login');
      store.dispatch({
        type: 'OPEN_SNACKBAR',
        content: 'You have been logged out. Please log in to continue.'
      });

      setTimeout(
        () => store.dispatch({ type: 'CLOSE_SNACKBAR' }),
        4000
      );
    }
  }
});

export const wrapRootElement = ({ element }) => (
  <Provider store={store}>
    <ClientContext.Provider value={client}>
      {element}
      <Snackbar/>
    </ClientContext.Provider>
  </Provider>
)